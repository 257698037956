'use client';

import { usePathname } from 'next/navigation';
import BlurSection from '@/components/ui/BlurSection';
import { useIsMobileDevice } from '@/hooks/useIsMobileDevice';
export default function MobileWarning({
  text
} = {
  text: ''
}) {
  const isMobileDevice = useIsMobileDevice();
  const pathname = usePathname();
  const allowedPaths = ['/register', '/login', '/forgot-password', 'reset-password', 'verify', 'setup-account'];
  if (allowedPaths.some(path => pathname.startsWith(path))) return null;
  if (!isMobileDevice) return null;
  return <BlurSection heading="Mobile Access Limited" isFixed data-sentry-element="BlurSection" data-sentry-component="MobileWarning" data-sentry-source-file="MobileWarning.tsx">
            <p className="text-gray-600 text-center">{text}</p>
        </BlurSection>;
}