const ROUTES = {
    MAINTENANCE: {
        path: '/maintenance',
        name: 'Maintenance',
    },
    HOME: {
        path: '/',
        name: 'Aura',
    },
    ACCOUNT: {
        path: '/account',
        name: 'Account',
    },
    ACCOUNT_PROFILE: {
        path: '/account/profile',
        name: 'Settings',
    },
    LOGIN: {
        path: '/login',
        name: 'Login',
    },
    REGISTER: {
        path: '/register',
        name: 'Register',
    },
    LOGOUT: {
        path: '/logout',
        name: 'Sign Out',
    },
    PLANS: {
        path: '/plans',
        name: 'Plans',
    },
    PRICING: {
        path: '/pricing',
        name: 'Pricing',
    },
    TEAMS: {
        path: '/account/teams',
        name: 'Teams',
    },
    TEAM_DETAIL: {
        path: '/account/teams/:id',
        name: 'Team Detail',
    },
    TEAM_AURA: {
        path: '/team/:id',
        name: 'Team',
    },
    MEMBERSHIP: {
        path: '/account/membership',
        name: 'Membership',
    },
    PRIVACY: {
        path: '/account/privacy',
        name: 'privacy',
    },
    ACCEPT_INVITE: {
        path: '/accept-invitation',
        name: 'Accept Invite',
    },
    FORGOT_PASSWORD: {
        path: '/forgot-password',
        name: 'Forgot Password',
    },
    RESET_PASSWORD: {
        path: '/reset-password',
        name: 'Reset Password',
    },
    INTAKE: {
        path: '/intake',
        name: 'Intake',
    },
    ADMIN: {
        path: '/admin',
        name: 'Dashboard',
    },
    ADMIN_USERS: {
        path: '/admin/users',
        name: 'Users',
    },
    ADMIN_USERS_DETAIL: {
        path: '/admin/users/:id',
        name: 'User Details',
    },
    ADMIN_USERS_ADD: {
        path: '/admin/users/invite',
        name: 'Invite User',
    },
    ADMIN_ATTRIBUTES: {
        path: '/admin/attributes',
        name: 'Archetype Attributes',
    },
    ADMIN_RECURRING_ATTRIBUTES: {
        path: '/admin/recurring-attributes',
        name: 'Recurring Attributes',
    },
    ADMIN_CATEGORIES: {
        path: '/admin/categories',
        name: 'Graphs',
    },
    ADMIN_CATEGORY_DETAIL: {
        path: '/admin/categories/:id',
        name: 'Category Detail',
    },
    ADMIN_PROMPTS: {
        path: '/admin/prompts',
        name: 'Prompts',
    },
    PROFILE_AURA: {
        path: '/account/aura',
        name: 'Aura',
    },
    VERIFY_EMAIL: {
        path: '/verify',
        name: 'Email Verify',
    },
    VERIFY_EMAIL_TOKEN: {
        path: '/verify/:id',
        name: 'Email Verify',
    },
    SETUP_ACCOUNT: {
        path: '/setup-account',
        name: 'Create Account',
    },
} as const;

// Automatically infer the 'Route' type from ROUTES
type Route = (typeof ROUTES)[keyof typeof ROUTES];

// Public routes array using inferred paths
export const PUBLIC_ROUTES = [
    ROUTES.ACCEPT_INVITE.path,
    ROUTES.LOGIN.path,
    ROUTES.PLANS.path,
    ROUTES.PRICING.path,
    ROUTES.FORGOT_PASSWORD.path,
    ROUTES.RESET_PASSWORD.path,
    ROUTES.SETUP_ACCOUNT.path,
    ROUTES.REGISTER.path,
    ROUTES.ACCEPT_INVITE.path,
    ROUTES.VERIFY_EMAIL.path,
    ROUTES.VERIFY_EMAIL_TOKEN.path,
];

export default ROUTES;

/**
 * Generates a dynamic path by replacing route parameters with the given values.
 *
 * @example
 * const route = ROUTES.TEAM_DETAIL;
 * const params = { id: 123 };
 * const path = getDynamicPath(route, params);
 * // path will be '/account/teams/123'
 */
export function getDynamicPath(route: Route, params: Record<string, string | number>): string {
    return Object.entries(params).reduce(
        (path, [key, value]) => path.replace(`:${key}`, String(value)),
        route.path,
    );
}
